// GoogleSERPPreview.js
import React from 'react';
import { Card } from 'react-bootstrap';

const GoogleSERPPreview = ({ title, description, url }) => {
  return (
    <Card className="mb-3 border-0 shadow-sm">
      <Card.Body>
        <div style={{ fontFamily: 'Arial, sans-serif', maxWidth: '600px' }}>
          <div style={{ color: '#1a0dab', fontSize: '18px', marginBottom: '5px' }}>
            {title || 'Your Ad Title'}
          </div>
          <div style={{ color: '#006621', fontSize: '14px', marginBottom: '5px' }}>
            {url || 'https://www.onlineclassified.in/your-ad-title'}
          </div>
          <div style={{ color: '#545454', fontSize: '13px', lineHeight: '1.4' }}>
            {description || 'This is where your ad description will appear. Make it compelling to attract potential customers. Include key details about your product or service.'}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default GoogleSERPPreview;