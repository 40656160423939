import React, { useState, useEffect } from 'react';
import { Container, Card, Button, Spinner, Alert, Pagination } from 'react-bootstrap';
import Masonry from 'react-masonry-css';
import { Link } from 'react-router-dom';
import EnquiryForm from './EnquiryForm';
import './AdsListing.css';
import CategoryFilter from './CategoryFilter';

function HomePageAds() {
  const [ads, setAds] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [adsPerPage] = useState(10);
  const [showEnquiryForm, setShowEnquiryForm] = useState(false);
  const [selectedAd, setSelectedAd] = useState(null);
  const [enquiryType, setEnquiryType] = useState(null);

  useEffect(() => {
    Promise.all([
      fetch('https://api.onlineclassified.in/api/ads/'),
      fetch('https://api.onlineclassified.in/api/categories/')
    ])
      .then(([adsResponse, categoriesResponse]) => 
        Promise.all([adsResponse.json(), categoriesResponse.json()])
      )
      .then(([adsData, categoriesData]) => {
        const sortedAds = adsData
          .filter(ad => ad.status === 1) // Only include ads with status 1
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setAds(sortedAds);
        setCategories(categoriesData);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setError('Failed to load data. Please try again later.');
        setLoading(false);
      });
  }, []);

  const handleCategoryChange = (categoryId) => {
    setSelectedCategories(prev => 
      prev.includes(categoryId)
        ? prev.filter(id => id !== categoryId)
        : [...prev, categoryId]
    );
    setCurrentPage(1); // Reset to first page when changing categories
  };

  const filteredAds = selectedCategories.length > 0
    ? ads.filter(ad => ad.category.some(catId => selectedCategories.includes(catId)))
    : ads;

  const breakpointColumnsObj = {
    default: 5,
    1100: 4,
    700: 3,
    500: 1
  };

  const indexOfLastAd = currentPage * adsPerPage;
  const indexOfFirstAd = indexOfLastAd - adsPerPage;
  const currentAds = filteredAds.slice(indexOfFirstAd, indexOfLastAd);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(filteredAds.length / adsPerPage);

  const handleEnquiry = (ad, type) => {
    setSelectedAd(ad);
    setEnquiryType(type);
    setShowEnquiryForm(true);
  };

  const handleEnquirySuccess = (result) => {
    console.log('Enquiry submitted:', result);
    setShowEnquiryForm(false);
    
    if (enquiryType === 'whatsapp') {
      window.open(`https://wa.me/${selectedAd.whatsapp}`, '_blank');
    } else if (enquiryType === 'call') {
      window.location.href = `tel:${selectedAd.contact}`;
    }
  };

  if (loading) {
    return <Spinner animation="border" className="d-block mx-auto my-5" />;
  }

  if (error) {
    return <Alert variant="danger" className="m-5">{error}</Alert>;
  }

  return (
    <Container fluid className="mt-5">
      <CategoryFilter 
        categories={categories}
        selectedCategories={selectedCategories}
        onCategoryChange={handleCategoryChange}
      />
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {currentAds.map((ad) => (
          <div key={ad.id} className="grid-item p-2">
            <Card>
              <Card.Img 
                variant="top" 
                src={ad.image} 
                className="card-img-top img-fluid" 
                alt={ad.title}
              />
              <Card.Body>
                <div className="d-flex justify-content-evenly mb-2">
                  <Button 
                    variant="outline-success" 
                    className="me-2 w-100 text-decoration-none btn-sm"
                    onClick={() => handleEnquiry(ad, 'whatsapp')}
                  >
                    <i className="bi bi-whatsapp"></i> Whatsapp
                  </Button>
                  <Button 
                    variant="outline-primary" 
                    className="w-100 text-decoration-none btn-sm"
                    onClick={() => handleEnquiry(ad, 'call')}
                  >
                    <i className="bi bi-telephone-outbound"></i> Call
                  </Button>
                </div>
                <Link 
                  to={`/ad/${ad.id}/${ad.slug}`} 
                  className="btn btn-outline-secondary w-100 text-decoration-none btn-sm"
                >
                  View Ad
                </Link>
              </Card.Body>
            </Card>
          </div>
        ))}
      </Masonry>
      <div className="d-flex justify-content-center mt-4">
        <Pagination>
          <Pagination.Prev 
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          >
            Previous
          </Pagination.Prev>
          {[...Array(totalPages).keys()].map((number) => (
            <Pagination.Item 
              key={number + 1} 
              active={number + 1 === currentPage}
              onClick={() => paginate(number + 1)}
            >
              {number + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next 
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
          >
            Next
          </Pagination.Next>
        </Pagination>
      </div>
      {selectedAd && (
        <EnquiryForm 
          show={showEnquiryForm} 
          handleClose={() => setShowEnquiryForm(false)} 
          adId={selectedAd.id}
          onSuccess={handleEnquirySuccess}
        />
      )}
    </Container>
  );
}

export default HomePageAds;