import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="pb-3 pt-3 small text-black">
      <Container fluid>
        <Row className="align-items-center">
          <Col md className="pb-2 pt-2">
            <p className="mb-0">© 2024. All Rights Reserved - Online Classifieds</p>
          </Col>
          <Col md="auto" className="pb-2 pt-2">
            <Link to="/" className="text-black">Home</Link>
            {' | '}
            <Link to="/about-us" className="text-black">About Us</Link>
            {' | '}
            <Link to="/privacy-policy" className="text-black">Privacy Policy</Link>
            {' | '}
            <Link to="/contact-us" className="text-black">Contact Us</Link>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;