import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Image, Button, Spinner, Alert } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import EnquiryForm from './EnquiryForm';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
  EmailIcon
} from 'react-share';

function AdDetail() {
  const [ad, setAd] = useState(null);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showEnquiryForm, setShowEnquiryForm] = useState(false);
  const [enquiryType, setEnquiryType] = useState(null);
  const { id, slug } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    Promise.all([
      fetch(`https://api.onlineclassified.in/api/ads/${id}/`).then(res => res.json()),
      fetch('https://api.onlineclassified.in/api/categories/').then(res => res.json())
    ])
    .then(([adData, categoriesData]) => {
      setAd(adData);
      setCategories(categoriesData);
      if (adData.slug && adData.slug !== slug) {
        navigate(`/ad/${id}/${adData.slug}`, { replace: true });
      }
      console.log('Ad data:', adData); // Debug log
    })
    .catch(err => setError(err.message))
    .finally(() => setLoading(false));
  }, [id, slug, navigate]);

  const handleEnquiry = (type) => {
    setEnquiryType(type);
    setShowEnquiryForm(true);
  };

  const handleEnquirySuccess = (result) => {
    console.log('Enquiry submitted:', result);
    
    if (enquiryType === 'whatsapp') {
      window.open(`https://wa.me/${ad.whatsapp}`, '_blank');
    } else if (enquiryType === 'call') {
      window.location.href = `tel:${ad.contact}`;
    }
  };

  if (loading) return <Spinner animation="border" className="d-block mx-auto my-5" />;
  if (error) return <Alert variant="danger" className="m-5">{error}</Alert>;
  if (!ad) return <Alert variant="warning" className="m-5">No ad found</Alert>;

  const adCategories = categories.filter(cat => ad.category.includes(cat.id));
  const schemaType = adCategories.length > 0 ? adCategories[0].name : 'Product';

  const schema = {
    "@context": "https://schema.org",
    "@type": schemaType,
    "name": ad.title,
    "description": ad.description,
    "image": ad.image,
  };

  const shareUrl = `https://www.onlineclassified.in/ad/${ad.id}/${ad.slug}`;
  const shareTitle = ad.title;
  const shareDescription = ad.description.substring(0, 160);

  // Ensure the image URL is absolute
  const absoluteImageUrl = ad.image.startsWith('http') ? ad.image : `https://www.onlineclassified.in${ad.image}`;

  console.log('Share data:', { shareUrl, shareTitle, shareDescription, imageUrl: absoluteImageUrl }); // Debug log

  return (
    <HelmetProvider>
      <Helmet>
        <title>{`${ad.title} | Online Classified`}</title>
        <meta name="description" content={shareDescription} />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={shareUrl} />
        <meta property="og:title" content={shareTitle} />
        <meta property="og:description" content={shareDescription} />
        <meta property="og:image" content={absoluteImageUrl} />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content={shareUrl} />
        <meta name="twitter:title" content={shareTitle} />
        <meta name="twitter:description" content={shareDescription} />
        <meta name="twitter:image" content={absoluteImageUrl} />

        <script type="application/ld+json">
          {JSON.stringify(schema)}
        </script>
      </Helmet>
      <section className="bg-light pb-5 text-black">
        <Container className="pb-5">
          <Row>
            <Col lg={4} className="order-lg-last text-center align-content-center">
              <Image 
                src={absoluteImageUrl} 
                className="bg-primary-subtle img-fluid rounded mb-5" 
                alt={ad.title}
              />
              <div className="d-flex justify-content-center mb-3">
                <FacebookShareButton url={shareUrl} quote={shareTitle} className="mx-2">
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <TwitterShareButton url={shareUrl} title={shareTitle} className="mx-2">
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
                <WhatsappShareButton url={shareUrl} title={shareTitle} className="mx-2">
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
                <LinkedinShareButton url={shareUrl} title={shareTitle} summary={shareDescription} source="Online Classified" className="mx-2">
                  <LinkedinIcon size={32} round />
                </LinkedinShareButton>
                <EmailShareButton url={shareUrl} subject={shareTitle} body={`Check out this ad:\n${shareDescription}\n\n${shareUrl}`} className="mx-2">
                  <EmailIcon size={32} round />
                </EmailShareButton>
              </div>
            </Col>
            <Col lg={8}>
              <h1 className="fw-bold mb-3 text-black">{ad.title}</h1>
              <p>{ad.description}</p>
              <div dangerouslySetInnerHTML={{ __html: ad.content.replace(/\n/g, '<p>') }} />
              <p>Categories: {adCategories.map(cat => cat.name).join(', ')}</p>
              <div className="d-flex justify-content-evenly"> 
                <Button 
                  variant="outline-success" 
                  className="me-2 w-100"
                  onClick={() => handleEnquiry('whatsapp')}
                >
                  <i className="bi bi-whatsapp"></i> Whatsapp
                </Button>
                <Button 
                  variant="outline-primary" 
                  className="w-100"
                  onClick={() => handleEnquiry('call')}
                >
                  <i className="bi bi-telephone-outbound"></i> Call
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
        <EnquiryForm 
          show={showEnquiryForm} 
          handleClose={() => setShowEnquiryForm(false)} 
          adId={ad.id}
          onSuccess={handleEnquirySuccess}
        />
      </section>
    </HelmetProvider>
  );
}

export default AdDetail;
