import React, { useState } from 'react';
import { Navbar, Nav, Container, Button, Offcanvas } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';

function Navigation() {
  const [show, setShow] = useState(false);
  const location = useLocation();
  const isCreateAdPage = location.pathname === '/create-ad';

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCreateAdClick = (e) => {
    if (isCreateAdPage) {
      e.preventDefault();
      console.log('Dispatching publishAd event');
      window.dispatchEvent(new Event('publishAd'));
    }
  };

  return (
    <Navbar bg="white" expand={false} className="py-lg-1">
      <Container fluid>
        <Navbar.Brand as={Link} to="/" className="fw-bold d-flex align-items-center text-danger text-decoration-none">
         <span>Online Classified</span>
          {/* <img
            src="assets/images/online-classified-logo.png"
            alt="Online Classified Logo"
            className="img-fluid"
            style={{ width: '200px' }}
          /> */}
        </Navbar.Brand>
        <div>
        <Button
            as={Link}
            to="/create-ad"
            variant="outline-success"
            className="btn-sm text-decoration-none"
            onClick={handleCreateAdClick}
          >
            {isCreateAdPage ? 'Publish Ad.' : 'Create Ad.'}
          </Button>
          <Button className="d-none btn-sm text-decoration-none" variant="outline-secondary" onClick={handleShow}>
            {/* <span className="navbar-toggler-icon"></span> */}
            Menu
          </Button>
        </div>
        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Menu</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <Nav.Link as={Link} to="/" onClick={handleClose}>Home</Nav.Link>
              <Nav.Link as={Link} to="/about-us" onClick={handleClose}>About Us</Nav.Link>
              <Nav.Link as={Link} to="/contact-us" onClick={handleClose}>Contact Us</Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Offcanvas>
      </Container>
    </Navbar>
  );
}

export default Navigation;
