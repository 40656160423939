import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from 'polotno';
import { Toolbar } from 'polotno/toolbar/toolbar';
import { PagesTimeline } from 'polotno/pages-timeline';
import { ZoomButtons } from 'polotno/toolbar/zoom-buttons';
import { SidePanel } from 'polotno/side-panel';
import { Workspace } from 'polotno/canvas/workspace';
import '@blueprintjs/core/lib/css/blueprint.css';
import { createStore } from 'polotno/model/store';
import { Button, ButtonGroup, Label } from '@blueprintjs/core';
import { saveAs } from 'file-saver';
import { Modal, Form, Image, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import './CreateAdPage.css';
import GoogleSERPPreview from './GoogleSERPPreview';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const store = createStore({
  // key: 'nFA5H9elEytDyPyvKL7T', // Replace with your Polotno license key
  key: '-JevPP97UjSjh9ejvwoK', // Replace with your Polotno license key
  showCredit: false,
});

store.addPage();

function CreateAdPage() {
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [adData, setAdData] = useState({
    title: '',
    description: '',
    content: '',
    contact: '',
    whatsapp: '',
    email: '',
    state: null,
    city: null,
    status: 2,
    user: 1,
    category: []
  });
  const [previewImage, setPreviewImage] = useState(null);
  const [categories, setCategories] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [filteredCities, setFilteredCities] = useState([]);
  const [errors, setErrors] = useState({
    title: '',
    description: '',
    contact: '',
    whatsapp: '',
  });

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['clean']
    ],
  };

  useEffect(() => {
    Promise.all([
      fetch('https://api.onlineclassified.in/api/categories/').then(res => res.json()),
      fetch('https://api.onlineclassified.in/api/states/').then(res => res.json()),
      fetch('https://api.onlineclassified.in/api/cities/').then(res => res.json())
    ]).then(([categoriesData, statesData, citiesData]) => {
      setCategories(categoriesData);
      setStates(statesData.map(state => ({ value: state.id, label: state.state_name })));
      setCities(citiesData);
    }).catch(error => console.error('Error fetching data:', error));

    const handlePublishAd = async () => {
      console.log('Publish Ad event received');
      const blob = await store.toBlob();
      setPreviewImage(URL.createObjectURL(blob));
      setShowModal(true);
    };
    window.addEventListener('publishAd', handlePublishAd);
    return () => window.removeEventListener('publishAd', handlePublishAd);
  }, []);

  useEffect(() => {
    if (adData.state) {
      setFilteredCities(cities.filter(city => city.state === adData.state)
        .map(city => ({ value: city.id, label: city.city_name })));
    } else {
      setFilteredCities([]);
    }
  }, [adData.state, cities]);

  const handleSaveAd = () => {
    const json = store.toJSON();
    const blob = new Blob([JSON.stringify(json)], { type: 'application/json' });
    saveAs(blob, 'my-ad.json');
  };

  const handleDownloadPNG = async () => {
    const blob = await store.toBlob();
    saveAs(blob, 'my-ad.png');
  };

  const handleDownloadPDF = () => {
    store.saveAsPDF({ fileName: 'my-ad.pdf' });
  };

  const validateField = (name, value) => {
    let error = '';
    switch (name) {
      case 'title':
        if (value.length < 60 || value.length > 70) {
          error = 'Title should be between 60 and 70 characters';
        }
        break;
      case 'description':
        if (value.length < 160 || value.length > 170) {
          error = 'Description should be between 160 and 170 characters';
        }
        break;
      case 'content':
        if (value.length < 500 || value.length > 2500) {
          error = 'Content should be between 500 and 2500 characters';
        }
        break;
      case 'contact':
      case 'whatsapp':
        if (value && value.length !== 10) {
          error = `${name.charAt(0).toUpperCase() + name.slice(1)} number should be exactly 10 digits`;
        }
        break;
      default:
        break;
    }
    return error;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAdData({ ...adData, [name]: value });
    const error = validateField(name, value);
    setErrors(prevErrors => ({ ...prevErrors, [name]: error }));
  };

  const handleContentChange = (content) => {
    setAdData({ ...adData, content: content });
    const error = validateField('content', content);
    setErrors(prevErrors => ({ ...prevErrors, content: error }));
  };
  

  const handleCategoryChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setAdData(prevState => ({
        ...prevState,
        category: [...prevState.category, parseInt(value)]
      }));
    } else {
      setAdData(prevState => ({
        ...prevState,
        category: prevState.category.filter(cat => cat !== parseInt(value))
      }));
    }
  };

  const handleStateChange = (selectedOption) => {
    setAdData({ ...adData, state: selectedOption ? selectedOption.value : null, city: null });
  };

  const handleCityChange = (selectedOption) => {
    setAdData({ ...adData, city: selectedOption ? selectedOption.value : null });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate all fields before submission
    const newErrors = {};
    Object.keys(adData).forEach(key => {
      const error = validateField(key, adData[key]);
      if (error) newErrors[key] = error;
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return; // Don't submit if there are errors
    }

    const imageBlob = await store.toBlob();
    const formData = new FormData();

    // Append all fields except image
    Object.keys(adData).forEach(key => {
      if (key === 'category') {
        adData[key].forEach(categoryId => {
          formData.append('category', categoryId);
        });
      } else {
        formData.append(key, adData[key]);
      }
    });

    // Append the image
    formData.append('image', imageBlob, 'ad_image.png');

    try {
      const response = await fetch('https://api.onlineclassified.in/api/ads/', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Server error:', errorData);
        throw new Error('Failed to publish ad');
      }

      const result = await response.json();
      console.log('Ad published successfully:', result);
      setShowModal(false);
      navigate('/thank-you', { state: { adData: result } });

    } catch (error) {
      console.error('Error publishing ad:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  return (
    <>
      <PolotnoContainer style={{ width: '100vw', height: '100vh' }}>
        <SidePanelWrap>
          <SidePanel store={store} />
        </SidePanelWrap>
        <WorkspaceWrap>
          <Toolbar store={store} downloadButtonDisabled />
          <Workspace store={store} />
          <ZoomButtons store={store} />
          <PagesTimeline store={store} />
          <ButtonGroup className='d-none' style={{ position: 'absolute', top: '10px', right: '10px' }}>
            <Button icon="download" text="Download PNG" onClick={handleDownloadPNG} />
            <Button icon="document" text="Download PDF" onClick={handleDownloadPDF} />
          </ButtonGroup>
        </WorkspaceWrap>
      </PolotnoContainer>

      <Modal 
        show={showModal} 
        onHide={() => setShowModal(false)} 
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        fullscreen
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title" className='small fw-bold text-danger'>Publish Your Ad:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={5} className="align-items-center justify-content-center">
              <p className='fw-bold small'>Google Search Preview:</p>
              <GoogleSERPPreview 
                title={adData.title}
                description={adData.description}
                url={`https://www.onlineclassified.in/${adData.title.toLowerCase().replace(/\s+/g, '-')}`}
              />
              <p className='fw-bold small mt-3'>Ad Image Preview:</p>
              {previewImage && (
                <Image 
                  src={previewImage} 
                  alt="Ad Preview" 
                  fluid 
                  className="rounded shadow img-fluid mb-5"
                  style={{ maxHeight: '80vh', objectFit: 'contain' }}
                />
              )}
            </Col>
            <Col md={7}>
              <p className='fw-bold'>Enter Your Ad Details:</p>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col xs={12}>
                    <Form.Group className="mb-3">
                      <Form.Label className='fw-bold small'>Title</Form.Label>
                      <Form.Control 
                        type="text" 
                        name="title" 
                        value={adData.title} 
                        onChange={handleInputChange} 
                        isInvalid={!!errors.title}
                        isValid={adData.title.length >= 50 && adData.title.length <= 60}
                        required 
                      />
                      <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
                      <Form.Text>{adData.title.length}/60 characters</Form.Text>
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <Form.Group className="mb-3">
                      <Form.Label className='fw-bold small'>Description</Form.Label>
                      <Form.Control 
                        as="textarea" 
                        rows={3} 
                        name="description" 
                        value={adData.description} 
                        onChange={handleInputChange} 
                        isInvalid={!!errors.description}
                        isValid={adData.description.length >= 150 && adData.description.length <= 160}
                        required 
                      />
                      <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
                      <Form.Text>{adData.description.length}/160 characters</Form.Text>
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                <Form.Group className="mb-3">
                  <Form.Label className='fw-bold'>Categories</Form.Label>
                  <div className="d-flex flex-wrap">
                    {categories.map(category => (
                      <Form.Check 
                        key={category.id}
                        type="checkbox"
                        id={`category-${category.id}`}
                        label={category.name}
                        value={category.id}
                        onChange={handleCategoryChange}
                        checked={adData.category.includes(category.id)}
                        className="me-2 mb-2"
                      />
                    ))}
                  </div>
                </Form.Group>
              </Col>
                  <Col xs={12} className="">
                  <Form.Group className="mb-3">
                    <Form.Label className='fw-bold small'>Content</Form.Label>
                    <ReactQuill 
                      theme="snow"
                      value={adData.content}
                      onChange={handleContentChange}
                      modules={modules}
                      style={{ height: '200px', marginBottom: '50px' }}
                    />
                    {errors.content && (
                      <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>{errors.content}</Form.Control.Feedback>
                    )}
                    <Form.Text>{adData.content.length}/2500 characters</Form.Text>
                  </Form.Group>
                  </Col>

                  <Col xs={12}>
                  <p className='fw-bold mb-0'>Enter Your Contact Details:</p>
                  <p className='small text-danger'>This Contact Information Will Use To Connect By The People Who Intrested In Your Ad.</p>
                  </Col> 
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label className='fw-bold small'>Contact</Form.Label>
                      <Form.Control 
                        type="text" 
                        name="contact" 
                        value={adData.contact} 
                        onChange={handleInputChange} 
                        isInvalid={!!errors.contact}
                        isValid={adData.contact.length === 10}
                        required 
                      />
                      <Form.Control.Feedback type="invalid">{errors.contact}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label className='fw-bold small'>WhatsApp</Form.Label>
                      <Form.Control 
                        type="text" 
                        name="whatsapp" 
                        value={adData.whatsapp} 
                        onChange={handleInputChange}
                        isInvalid={!!errors.whatsapp}
                        isValid={adData.whatsapp.length === 10}
                      />
                      <Form.Control.Feedback type="invalid">{errors.whatsapp}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label className='fw-bold small'>Email</Form.Label>
                      <Form.Control type="email" name="email" value={adData.email} onChange={handleInputChange} />
                    </Form.Group>
                  </Col>

                  <p className='fw-bold'>Enter Your Location:</p>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label className='fw-bold small'>State</Form.Label>
                      <Select
                        options={states}
                        value={states.find(option => option.value === adData.state)}
                        onChange={handleStateChange}
                        placeholder="Select State"
                        isClearable
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label className='fw-bold small'>City</Form.Label>
                      <Select
                        options={filteredCities}
                        value={filteredCities.find(option => option.value === adData.city)}
                        onChange={handleCityChange}
                        placeholder="Select City"
                        isDisabled={!adData.state}
                        isClearable
                      />
                    </Form.Group>
                  </Col>
            </Row>
            <Button 
              className="btn btn-success bg-success text-white link-warning" 
              type="submit"
            >
              Submit Ad.
            </Button>
          </Form>
        </Col>
      </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CreateAdPage;