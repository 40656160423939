import React from 'react';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import { useLocation, Link } from 'react-router-dom';

function ThankYouPage() {
  const location = useLocation();
  const adData = location.state?.adData;

  if (!adData) {
    return (
      <Container className="mt-5">
        <h1>Error: No ad data found</h1>
        <Link to="/" className="btn btn-primary">Return to Home</Link>
      </Container>
    );
  }

  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <h1 className="text-center mb-2 text-success fs-4">Thank You for Publishing Your Ad!</h1>
          <p className="text-center text-danger small fw-bold">Note: <span>Your Ad is go under for review it will be publish in 30 Minutes</span></p>
          <Card>
            <Card.Body>
              <Row>
                <Col md={6}>
                  <Image src={adData.image} alt={adData.title} fluid className="mb-3" />
                </Col>
                <Col md={6}>
                  <h2>{adData.title}</h2>
                  <p><strong>Description:</strong> {adData.description}</p>
                  <p><strong>Content:</strong> {adData.content}</p>
                  <p><strong>Location:</strong> {adData.location}</p>
                  <p><strong>Contact:</strong> {adData.contact}</p>
                  {adData.whatsapp && <p><strong>WhatsApp:</strong> {adData.whatsapp}</p>}
                  <p><strong>Published on:</strong> {new Date(adData.created_at).toLocaleString()}</p>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <div className="text-center mt-4">
            <Link to="/" className="btn btn-primary me-3">Return to Home</Link>
            <Link to="/create-ad" className="btn btn-outline-primary">Create Another Ad</Link>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default ThankYouPage;