import React from 'react';
import { Helmet } from 'react-helmet';
import SEO from '../components/SEO';

function StructuredData({ data }) {
  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(data)}
      </script>
    </Helmet>
  );
}

const PrivacyPolicy = () => {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "url": "https://onlineclassified.in/about-us",
    "name": "Privacy Policy | Online Classified",
    "description": "India No. 1 Classified Website. Browse our wide selection of classified ads for great deals on products and services in your area."
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <SEO 
        title="Privacy Policy | Online Classified"
        description="India No. 1 Classified Website. For the People By The People. Browse our wide selection of classified ads for great deals on products and services in your area."
        keywords="india no 1 classified website, online classified, online marketplace, local deals, dehradun classified, post ad online, free online classified"
      />
      <StructuredData data={structuredData} />

      <h2 className="text-2xl font-bold mb-4">Privacy Policy</h2>
      <h3 className="text-xl font-semibold mb-2">Introduction</h3>
      <p className="mb-4">Welcome to Online Classified ("we," "our," or "us"). Your privacy is important to us, and we are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and safeguard your information when you use our website and services.</p>
      
      <h3 className="text-xl font-semibold mb-2">Information We Collect</h3>
      <p className="mb-2">When you use our platform, we may collect the following types of information:</p>
      <ul className="list-disc pl-6 mb-4">
        <li><strong>Personal Information</strong>: Name, email address, phone number, and other contact details.</li>
        <li><strong>User Content</strong>: Information you provide when creating ads, such as descriptions, images, and other content.</li>
        <li><strong>Usage Data</strong>: Information about how you interact with our website, including IP address, browser type, access times, and referring website addresses.</li>
        <li><strong>Cookies and Tracking Technologies</strong>: We use cookies and similar technologies to enhance your experience on our website.</li>
      </ul>

      <h3 className="text-xl font-semibold mb-2">How We Use Your Information</h3>
      <p className="mb-2">We use the information we collect for various purposes, including:</p>
      <ul className="list-disc pl-6 mb-4">
        <li><strong>Providing and Improving Our Services</strong>: To facilitate the creation and posting of ads, manage your account, and provide customer support.</li>
        <li><strong>Communications</strong>: To send you notifications, updates, and promotional materials.</li>
        <li><strong>Analytics</strong>: To analyze usage patterns and improve our website and services.</li>
        <li><strong>Legal Compliance</strong>: To comply with applicable laws, regulations, and legal processes.</li>
      </ul>

      <h3 className="text-xl font-semibold mb-2">Sharing Your Information</h3>
      <p className="mb-2">We may share your information with:</p>
      <ul className="list-disc pl-6 mb-4">
        <li><strong>Service Providers</strong>: Third-party vendors who help us operate and improve our services.</li>
        <li><strong>Legal Authorities</strong>: When required by law, regulation, or legal process.</li>
        <li><strong>Business Transfers</strong>: In connection with a merger, acquisition, or sale of assets.</li>
      </ul>

      <h3 className="text-xl font-semibold mb-2">Your Choices</h3>
      <p className="mb-2">You have the following rights regarding your personal information:</p>
      <ul className="list-disc pl-6 mb-4">
        <li><strong>Access and Correction</strong>: You can access and update your personal information through your account settings.</li>
        <li><strong>Opt-Out</strong>: You can opt out of receiving promotional communications by following the unsubscribe instructions in the emails we send.</li>
        <li><strong>Deletion</strong>: You can request the deletion of your personal information by contacting us.</li>
      </ul>

      <h3 className="text-xl font-semibold mb-2">Security</h3>
      <p className="mb-4">We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, use, or disclosure.</p>

      <h3 className="text-xl font-semibold mb-2">Changes to This Privacy Policy</h3>
      <p className="mb-4">We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. We encourage you to review this Privacy Policy periodically.</p>

      <h3 className="text-xl font-semibold mb-2">Contact Us</h3>
      <p className="mb-2">If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at:</p>
      <p className="mb-4"><strong>Online Classified</strong><br />Email: <a href="mailto:onlineclassifieddotin@gmail.com" className="text-blue-600 hover:underline">onlineclassifieddotin@gmail.com</a></p>
      <hr className="my-4" />
      <p className="mb-4">By using our website and services, you consent to the terms of this Privacy Policy. Thank you for being a part of the Online Classified community!</p>
    </div>
  );
};

export default PrivacyPolicy;