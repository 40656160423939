import React, { useState, useEffect } from 'react';
import { Container, Card, Button, Spinner, Alert, Pagination } from 'react-bootstrap';
import Masonry from 'react-masonry-css';
import { useLocation, Link } from 'react-router-dom';
import EnquiryForm from '../components/EnquiryForm'; // Make sure to import the EnquiryForm component

function SearchResults() {
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [adsPerPage] = useState(20);
  const [showEnquiryForm, setShowEnquiryForm] = useState(false);
  const [selectedAd, setSelectedAd] = useState(null);
  const [enquiryType, setEnquiryType] = useState(null);
  const location = useLocation();

  const searchQuery = new URLSearchParams(location.search).get('q');

  useEffect(() => {
    if (searchQuery) {
      fetchSearchResults(searchQuery);
    }
  }, [location.search]);

  const fetchSearchResults = async (query) => {
    setLoading(true);
    try {
      const response = await fetch(`https://api.onlineclassified.in/api/ads/`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const filteredResults = data.filter(ad => 
        ad.title.toLowerCase().includes(query.toLowerCase()) ||
        ad.description.toLowerCase().includes(query.toLowerCase())
      );
      setSearchResults(filteredResults.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)));
      setLoading(false);
    } catch (error) {
      console.error('Error fetching search results:', error);
      setError('Failed to fetch search results. Please try again later.');
      setLoading(false);
    }
  };

  const breakpointColumnsObj = {
    default: 5,
    1100: 3,
    700: 2,
    500: 1
  };

  const indexOfLastAd = currentPage * adsPerPage;
  const indexOfFirstAd = indexOfLastAd - adsPerPage;
  const currentAds = searchResults.slice(indexOfFirstAd, indexOfLastAd);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(searchResults.length / adsPerPage);

  const handleEnquiry = (ad, type) => {
    setSelectedAd(ad);
    setEnquiryType(type);
    setShowEnquiryForm(true);
  };

  const handleEnquirySuccess = (result) => {
    console.log('Enquiry submitted:', result);
    setShowEnquiryForm(false);
    
    if (enquiryType === 'whatsapp') {
      window.open(`https://wa.me/${selectedAd.whatsapp}`, '_blank');
    } else if (enquiryType === 'call') {
      window.location.href = `tel:${selectedAd.contact}`;
    }
  };

  if (loading) {
    return <Spinner animation="border" className="d-block mx-auto my-5" />;
  }

  if (error) {
    return <Alert variant="danger" className="m-5">{error}</Alert>;
  }

  return (
    <Container fluid className="mt-5">
      <h2 className='fs-5'>Search Results for: <span className='text-danger'>{searchQuery}</span></h2>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {currentAds.map((ad) => (
          <div key={ad.id} className="grid-item p-2">
            <Card>
              <Card.Img 
                variant="top" 
                src={ad.image} 
                className="card-img-top img-fluid" 
                alt={ad.title}
              />
              <Card.Body>
                <div className="d-flex justify-content-evenly mb-2">
                  <Button 
                    variant="outline-success" 
                    className="me-2 w-100 text-decoration-none btn-sm"
                    onClick={() => handleEnquiry(ad, 'whatsapp')}
                  >
                    <i className="bi bi-whatsapp"></i> Whatsapp
                  </Button>
                  <Button 
                    variant="outline-primary" 
                    className="w-100 text-decoration-none btn-sm"
                    onClick={() => handleEnquiry(ad, 'call')}
                  >
                    <i className="bi bi-telephone-outbound"></i> Call
                  </Button>
                </div>
                <Link to={`/ad/${ad.id}/${ad.slug}`} className="btn btn-outline-secondary w-100 text-decoration-none btn-sm">
                  View Ad
                </Link>
              </Card.Body>
            </Card>
          </div>
        ))}
      </Masonry>
      {searchResults.length === 0 && <p>No results found.</p>}
      <div className="d-flex justify-content-center mt-4">
        <Pagination>
          <Pagination.Prev 
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          >
            Previous
          </Pagination.Prev>
          {[...Array(totalPages).keys()].map((number) => (
            <Pagination.Item 
              key={number + 1} 
              active={number + 1 === currentPage}
              onClick={() => paginate(number + 1)}
            >
              {number + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next 
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
          >
            Next
          </Pagination.Next>
        </Pagination>
      </div>
      {selectedAd && (
        <EnquiryForm 
          show={showEnquiryForm} 
          handleClose={() => setShowEnquiryForm(false)} 
          adId={selectedAd.id}
          onSuccess={handleEnquirySuccess}
        />
      )}
    </Container>
  );
}

export default SearchResults;