import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';
import SEO from '../components/SEO';

function StructuredData({ data }) {
  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(data)}
      </script>
    </Helmet>
  );
}

function AboutUs() {

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "url": "https://onlineclassified.in/about-us",
    "name": "About Us | Online Classified",
    "description": "India No. 1 Classified Website. Browse our wide selection of classified ads for great deals on products and services in your area."
  };

  return (
    <Container className="mt-4">
      <SEO 
        title="About US | Online Classified"
        description="India No. 1 Classified Website. For the People By The People. Browse our wide selection of classified ads for great deals on products and services in your area."
        keywords="india no 1 classified website, online classified, online marketplace, local deals, dehradun classified, post ad online, free online classified"
      />
      <StructuredData data={structuredData} />
      <h1>About Us</h1>
      <Row>
        <Col>
        <p>Welcome to Online Classified - your go-to destination for buying, selling, and connecting within our vibrant community! 🌄</p>
        <p>At Online Classifieds, we make it simple for you to create and post ads for free, helping you reach local buyers and sellers effortlessly. Whether you&rsquo;re looking to sell pre-loved items, find a rental property, promote your services, or discover great deals, our platform is designed to connect you with like-minded people in Dehradun.</p>
        <p>Our Motto:<br />Create &amp; Post Your Ads For Free &amp; Get Leads For Free.</p>
        <p>Why choose us?</p>
        <p>✅ Free Ads for Sellers: As a seller, you can create and post your ads absolutely free. Showcase your items or services and start getting leads without spending a dime!</p>
        <p>✅ Local Leads: Get real-time leads and inquiries from people right here in your city.</p>
        <p>✅ Easy to Use: Our page is user-friendly, making it easy for anyone to browse, post, and connect.</p>
        <p>✅ Community Focused: We're all about supporting local businesses and individuals, helping you make connections that matter.</p>
        <p>Join us today and start exploring the endless opportunities Dehradun has to offer. Whether you&rsquo;re here to buy, sell, or just browse, we&rsquo;re glad to have you as part of our community!</p>
        </Col>
      </Row>
    </Container>
  );
}

export default AboutUs;