import React from 'react';
import { Form, Offcanvas, Button } from 'react-bootstrap';

function CategoryFilter({ categories, selectedCategories, onCategoryChange }) {
  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="outline-primary" size="sm" onClick={handleShow} className="mb-3">
        Show Category
      </Button>

      <Offcanvas show={show} onHide={handleClose} scroll={true} backdrop={true}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filter by Category</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="d-flex flex-column">
            {categories.map(category => (
              <Form.Check 
                key={category.id}
                type="checkbox"
                id={`category-${category.id}`}
                label={category.name}
                checked={selectedCategories.includes(category.id)}
                onChange={() => onCategoryChange(category.id)}
                className="mb-2 fw-bold text-dark"
              />
            ))}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default CategoryFilter;