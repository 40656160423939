import React from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import SEO from '../components/SEO';

function StructuredData({ data }) {
  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(data)}
      </script>
    </Helmet>
  );
}

function ContactUs() {
  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here
    console.log('Form submitted');
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "url": "https://onlineclassified.in/about-us",
    "name": "Contact Us | Online Classified",
    "description": "India No. 1 Classified Website. Browse our wide selection of classified ads for great deals on products and services in your area."
  };

  return (
    <Container className="mt-4">
      <SEO 
        title="Contact Us| Online Classified"
        description="India No. 1 Classified Website. For the People By The People. Browse our wide selection of classified ads for great deals on products and services in your area."
        keywords="india no 1 classified website, online classified, online marketplace, local deals, dehradun classified, post ad online, free online classified"
      />
      <StructuredData data={structuredData} />

      <h1>Contact Us</h1>
      <span>Email:</span><a href="mailto:onlineclassifieddotin@gmail.com" className=''>onlineclassifieddotin@gmail.com</a>
      {/* <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formName">
          <Form.Label>Name</Form.Label>
          <Form.Control type="text" placeholder="Enter your name" required />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control type="email" placeholder="Enter your email" required />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formMessage">
          <Form.Label>Message</Form.Label>
          <Form.Control as="textarea" rows={3} placeholder="Enter your message" required />
        </Form.Group>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form> */}
    </Container>
  );
}

export default ContactUs;