import React from 'react';
import { Helmet } from 'react-helmet';
import HomeSearchBanner from '../components/HomeSearchBanner';
import HomePageAds from '../components/HomePageAds';
import SEO from '../components/SEO';

function StructuredData({ data }) {
  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(data)}
      </script>
    </Helmet>
  );
}

function Home() {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "url": "https://www.onlineclassified.in/",
    "name": "Online Classified",
    "description": "India No. 1 Classified Website. Browse our wide selection of classified ads for great deals on products and services in your area."
  };

  return (
    <>
      <SEO 
        title="Online Classified | India No.1 Classified Website | For the People By The People For Free Ads. Posting"
        description="India No. 1 Classified Website. For the People By The People For Free Ads. Posting. Browse our wide selection of classified ads for great deals on products and services in your area."
        keywords="india no 1 classified website, online classified, online marketplace, local deals, dehradun classified, post ad online, free online classified"
      />
      <StructuredData data={structuredData} />
      <section className='bg-light'><HomeSearchBanner /></section>
      <HomePageAds />
    </>
  );
}

export default Home;