import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import { debounce } from 'lodash';
import { useNavigate, Link, useLocation } from 'react-router-dom';

function HomeSearchBanner() {
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  const isSearchPage = location.pathname === '/search';

  const fetchSuggestions = useCallback(
    debounce(async (query) => {
      if (query.length < 2) {
        setSuggestions([]);
        return;
      }

      try {
        const response = await fetch(`https://api.onlineclassified.in/api/ads/`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const filteredSuggestions = data.filter(ad => 
          ad.title.toLowerCase().includes(query.toLowerCase()) ||
          ad.description.toLowerCase().includes(query.toLowerCase())
        ).slice(0, 5); // Limit to 5 suggestions
        setSuggestions(filteredSuggestions);
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
    }, 300),
    []
  );

  useEffect(() => {
    fetchSuggestions(searchQuery);
  }, [searchQuery, fetchSuggestions]);

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      navigate(`/search?q=${encodeURIComponent(searchQuery)}`);
    } else if (isSearchPage) {
      navigate('/');
    }
  };

  const getDaysAgo = (dateString) => {
    const days = Math.ceil((new Date() - new Date(dateString)) / (1000 * 60 * 60 * 24));
    return days === 1 ? '1 day ago' : `${days} days ago`;
  };

  return (
    <Container>
      <div className="mb-4 pt-5 pb-5 rounded-3">
        <h1 className="fw-bold text-center text-danger">Online Classified</h1>
        <p className="fw-bold mb-4 text-center text-primary-emphasis">
          Create & Post Your Ads. For Free & Get Leads For Free.
        </p>
        <Row className="justify-content-center mb-4">
          <Col md={6}>
            <Form onSubmit={handleSearch}>
              <Form.Group className="mb-2">
                <Form.Control
                  required
                  type="text"
                  placeholder="Search Any Thing...."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </Form.Group>
            </Form>
            {suggestions.length > 0 && (
              <div className="mt-3">
                {suggestions.map(ad => (
                  <Card key={ad.id} className="mb-2">
                    <Card.Body className="d-flex align-items-center">
                      <img
                        className='rounded img-fluid'
                        src={ad.image} 
                        alt={ad.title} 
                        style={{ width: '100px', height: '100px', objectFit: 'cover', marginRight: '10px' }} 
                      />
                      <div className="flex-grow-1">
                        <Card.Title className="mb-1 fw-bold small">{ad.title.substring(0, 70)}</Card.Title>
                        <p className="text-muted small">{getDaysAgo(ad.created_at)}</p>
                        <Link to={`/ad/${ad.id}/${ad.slug}`} className="btn btn-sm btn-outline-secondary text-decoration-none">
                          View Ad
                        </Link>
                      </div>
                    </Card.Body>
                  </Card>
                ))}
                <div className="d-grid mt-3">
                  <Button variant="outline-danger btn-sm" type="submit" className="w-50 mx-auto" onClick={handleSearch}>
                    {isSearchPage ? 'View All' : 'Search Ad.'}
                  </Button>
                </div>
              </div>
            )}
            {suggestions.length === 0 && (
              <div className="d-grid mt-3">
                <Button variant="outline-danger btn-sm" type="submit" className="w-50 mx-auto" onClick={handleSearch}>
                  {isSearchPage ? 'View All' : 'Search Ad.'}
                </Button>
              </div>
            )}
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default HomeSearchBanner;